header {
    position: relative;
}

div#accessconfig {
    display: flex;
}

.header {
    width: 100%;
    height: 130px;
    position: fixed;
    background-color: $color-main;
    z-index: 990;
    transition: $duration;

    &__container {
        height: 100%;
        display: flex;
        justify-content: space-between;
    }

    &-logo-menu {
        display: flex;
        column-gap: 10px;
    }

    &-logo {
        height: 150px;
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 30px;
        z-index: 11;

        &::before {
            content: '';
            width: 1500px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background-color: $color-white;
            box-shadow: $shadow;
            z-index: -1;
        }

        &__identity {
            display: none;
        }

        &__image {
            width: 260px;
            height: 75px;
            display: block;
            object-fit: cover;
            transition: $duration;
        }
    }

    &-tools-socials {
        display: flex;
        align-items: center;
        padding-top: 13px;


        .socials {
            display: none;
        }
    }

    &-tools {
        display: flex;
        align-items: center;
        column-gap: 20px;
    
        &__item {
            width: 40px;
            height: 40px;
            margin: 0;
            padding: 0;
            border: 2px solid $color-white;
            border-radius: $border-radius--round;
            transition: $duration;
            
            svg {
                width: 40px;
                height: 40px;
                fill: $color-white;
                transition: $duration;
            }

            &--search {
                display: none;
            }

            &:hover,
            &:focus {
                border: 2px solid $color-white;
                background-color: $color-white;

                svg {
                    fill: $color-main;
                }
            }

            &--search {
                &--disabled {
                    pointer-events: none;
                    opacity: .2;
                }
            }
        }
    }

    .tools-view {
        width: 100%;
        position: absolute;
        top: 130px;
        left: 0;
        z-index: 1;
    
        .container--tools-view {
            display: flex;
            justify-content: flex-end;
        }
    
        #google_translate_element {
            display: none;
        }
    
        .profils {
            width: 240px;
            height: auto;
            position: relative;
            display: none;
            background: $color-main;
            padding: 15px 20px 20px;
            margin-right: 16px;
    
            &.show-list {
                display: flex;
                flex-direction: column;
            }
    
            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 0;
            }
    
            &__list {    
                li {
                    a {
                        width: 100%;
                        position: relative;
                        display: flex;
                        align-items: center;
                        color: $color-white;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        transition: all $duration;
    
                        &:hover,
                        &:focus {
                            padding-left: 10px;
                        }
    
                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            display: flex;
                            align-items: center;
                            color: $color-white;
                        }
                    }

                    &:first-child a {
                        padding-top: 0;
                        border-top: none;
                    }

                    &:last-child a {
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    &-profil {
        width: 132px;
        height: 100%;
        background-color: $color-white;
        padding: 25px 15px 10px 30px;
        margin-left: 10px;
        transition: $duration;

        &--active,
        &:hover,
        &:focus {
            background-color: $color-light;
            padding-top: 35px;
            cursor: pointer;
        }

        &__content {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            row-gap: 2px;
            font-size: $font-size--text-medium;
            font-weight: $font-weight-semibold;
            color: $color-main;
            letter-spacing: 0.05em;

            svg {
                width: 20px;
                height: 21px;
                fill: $color-main;
            }

            div {
                display: flex;
                flex-direction: column;
            }

            span {
                font-weight: $font-weight-black;
                text-transform: uppercase;
                margin-top: -5px;
            }
        }

        .profils {
            display: none;
        }
    }

    &--fixed {
        height: 80px;
        box-shadow: $shadow;

        .header {
            height: 80px;

            &-logo {
                height: 90px;
                padding-right: 24px;

                &__image {
                    width: 200px;
                    height: 58px;
                }
            }

            &-tools-socials {
                padding-top: 0px;
            }

            .tools-view {
                top: 80px;

                .profils {
                    margin-right: 82px;
                }
            }

            &-profil {
                padding: 10px;

                &__content {
                    justify-content: flex-end;
                    align-items: center;
                    row-gap: 0;
                }

                svg {
                    display: none;
                }
            }
        }

        #nav-main {
            .nav-main-item {
                .nav-main-link {
                    height: 80px;
                    justify-content: center;
                    padding-bottom: 0 !important;
                    
                    &:hover,
                    &:focus {
                        padding-bottom: 0;
                    }
                }

                .sub-menu {
                    top: 80px;
                }
            }
        }

        // iframe.goog-te-menu-frame.skiptranslate {
        //     top: 80px;
        // }
    }   
    
    &--search {
        .header-tools__item--search {
            display: flex;
        }
    }
}

.tools {
    // Google translate
    &__translate {
        position: relative;
        display: flex;

        &__wrapper {
            position: absolute;
            left: 50%;
            top: 60px;
            z-index: 15;
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 10px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-white;
            border-radius: $border-radius;
            transform: translateX(-50%);
            filter: drop-shadow(0px 0px 1px rgba(27, 35, 54, 0.08)) drop-shadow(1px 1px 10px rgba(27, 35, 54, 0.08));
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: -6px;
                display: block;
                width: 30px;
                height: 30px;
                background-color: $color-white;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family;
                font-size: $font-size--text;
                font-weight: $font-weight;
        
                &:hover, &:focus {
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            position: absolute;
            bottom: -10px;
            left: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-main;
            border-radius: $border-radius--round;
            transition: background-color $duration ease-in-out;

            svg {
                width: 26px;
                height: 26px;
                fill: $color-white;
                transition: fill $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-white;

                svg {
                    fill: $color-main;
                }
            }
        }
    }
}

body:not(.home) {
    .header {
        &-logo-menu {
            column-gap: 15px;
        }

        &-profil {
            margin-left: 0;
        }

        &-tools {
            column-gap: 15px;

            &__item--search {
                display: flex;
            }
        }

        .tools-view .profils.show-list {
            margin-right: 60px;
        }

        &--fixed {
            .header .tools-view .profils.show-list {
                margin-right: 127px;
            }

            #nav-main .nav-main-item .nav-main-link {
                padding: 0 30px;
            }
        }
    }


    #nav-main .nav-main-item {
        .nav-main-link {
            padding: 0 25px 35px;
        }

        &:hover,
        &:focus {
            .nav-main-link {
                padding-bottom: 25px;
            }
        }
    }
}

#uci_link{
    display: none;
}

// iframe.goog-te-menu-frame.skiptranslate {
//     position: fixed;
//     top: 130px !important;
//     left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
//     box-shadow: none;
//     border: none;
// }


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover,
        &:focus {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {    
    .header {    
        &-logo-menu {
            column-gap: 0;
        }

        &-logo {
            padding-right: 20px;
    
            &__image {
                width: 210px;
                height: 60px;
            }
        }

        &-tools {
            column-gap: 9px;
        }

        .tools-view {
            top: 130px;
            left: 0;
        
            .profils {
                margin-right: 0;
            }
        }
    
        &-profil {
            width: 110px;
            padding: 25px 10px 10px 20px;
            margin-left: 0;

            &--active,
            &:hover,
            &:focus {
                padding-top: 35px;
            }
        }
    
        &--fixed {    
            .header {    
                &-logo {
                    padding-right: 15px;
    
                    &__image {
                        width: 175px;
                        height: 50px;
                    }
                }

                .tools-view {    
                    .profils {
                        margin-right: 23px;
                    }
                }
            }
        }   
    }

    body:not(.home) {
        .header {
            &-logo-menu {
                column-gap: 10px;
            }
    
            &-tools {
                column-gap: 10px;
            }

            .tools-view .profils.show-list {
                margin-right: 29px;
            }

            #nav-main .nav-main-item {
                .nav-main-link {
                    padding: 0 13px 35px;
                }
        
                &:hover,
                &:focus {
                    .nav-main-link {
                        padding-bottom: 25px;
                    }
                }
            }

            &--fixed {
                #nav-main .menu__nav__list .nav-main-link {
                    padding: 0 17px 0;
                }

                .header .tools-view .profils.show-list {
                    margin-right: 37px;
                }
            }
        }
    }

    // iframe.goog-te-menu-frame.skiptranslate {
    //     left: calc(100% - (100% - 960px)/2 - 335px)!important;
    // }
}


// 960
@media screen and (max-width: $medium) {
    .header {    
        height: 90px;
        position: relative;

        &__container {
            column-gap: 44px;
        }

        &-logo-menu {
            flex: 1;
            justify-content: space-between;
        }

        &-logo {
            height: 100px;
            padding-right: 30px;
        }

        &-tools-socials {
            padding: 0;
        }

        &-tools {    
            &__item,
            &__item svg {
                width: 32px;
                height: 32px;
            }

            &__item--search {
                display: flex;
            }
        }
        
        .tools-view {
            top: 130px;
            left: 0;
        
            .profils {
                margin-right: 0;
            }
        }
    
        &-profil {
            width: 100%;
            height: 70px;
            background-color: $color-main;
            padding: 0;
            margin-left: 0;

            &--active,
            &:hover,
            &:focus {
                padding-top: 0;
                background-color: $color-main;
            }

            &__content {
                flex-direction: row;
                justify-content: center;
                align-items: center;
                column-gap: 10px;
                font-size: $font-size--text-normal;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            } 

            .profils {
                width: 100%;
                background-color: $color-light;
                padding: 20px 0;

                &.show {
                    display: flex;
                }

                &__list {
                    width: 100%;
                    max-width: 640px;
                    display: flex;
                    flex-direction: column;
                    padding: 0 10px;
                    margin: 0 auto;

                    li a {
                        width: 100%;
                        position: relative;
                        display: flex;
                        align-items: center;
                        color: #202020;
                        font-weight: 700;
                        padding: 15px 30px 15px 0;
                        margin-bottom: 1px;
                        border-bottom: 1px solid #777;
                        transition: all .5s;
                    }

                    li:last-child a {
                        border: none;
                    }
                }
            }
        }  
    }

    body:not(.home) .header #nav-main .nav-main-item .nav-main-link {
        padding: 0 20px;
    }

    body:not(.home) .header #nav-main .nav-main-item:focus .nav-main-link, 
    body:not(.home) .header #nav-main .nav-main-item:hover .nav-main-link {
        padding-bottom: 0;
    }

    .tools__translate__wrapper {
        top: 61px;
    }


    // iframe.goog-te-menu-frame.skiptranslate {
    //     left: calc(100% - (100% - 640px)/2 - 335px)!important;
    //     top: 90px!important;
    // }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {
    .header {
        height: 150px;
        background: linear-gradient(to bottom, $color-main 0%, $color-main 60px, $color-white 60px, $color-white 100%);
    
        &__container {
            flex-direction: column-reverse;
        }
    
        &-logo-menu {
            height: 90px;
            justify-content: flex-start;
            align-items: center;
            column-gap: 45px;
        }
    
        &-logo {
            height: 100%;
            padding-right: 0;
    
            &::before {
                display: none;
            }
    
            &__image {
                width: 161px;
                height: 46px;
            }
        }
    
        &-tools-socials {
            height: 60px;
            justify-content: space-between;
    
            .socials {
                display: flex;
                flex-direction: row;
                column-gap: 10px;
                position: relative;
                top: unset;
                right: unset;

                &__item,
                &__item svg {
                    width: 32px;
                    height: 32px;
                }

                &__item {
                    padding: 0;
                    margin: 0;
                    border: 2px solid $color-white;
                    border-radius: $border-radius--round;

                    &:hover,
                    &:focus {
                        padding: 0;
                    }
                }

                &__text {
                    display: none;
                }
            }
        }
    
        .tools-view {
            top: 130px;
            left: 0;
        
            .profils {
                width: 240px;
                padding: 15px 20px 20px;
                margin-right: 18px;
        
                &__list {
                    max-width: 320px;
                    margin-top: 20px;
        
                    li {
                        a {
                            padding: 10px 20px 10px 0px;
        
                            &:hover,
                            &:focus {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    body:not(.home) .header-logo-menu {
        column-gap: 45px;
    }

    .tools__translate__wrapper {
        top: 46px;
    }

    // iframe.goog-te-menu-frame.skiptranslate {
    //     position: absolute!important;
    //     top: 150px!important;
    //     right: auto!important;
    //     left: 50%!important;
    //     transform: translateX(-50%) !important;
    // }
}
