.breadcrumb {
    position: relative;
    transform: translateY(-50%);

    &::after {
        content: '';
        width: 100%;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-color: $color-white;
        background-image: url(/wp-content/themes/saint-paul-trois-chateaux/assets/src/images/sprite-images/Motifs/motif-bleu.png);
        background-repeat: repeat;
        z-index: 1;
    }

    &__container {
        position: relative;
        display: inline-block;
        background-color: $color-white;
        box-shadow: $shadow;
        z-index: 2;
    }

    ul  {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 15px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-dark;
        padding: 15px 10px;
        transition: $duration;

        // "Vous êtes ici" style
        //&:first-child {
        //    font-size: $font-size--text-small;
        //    font-weight: $font-weight-bold;
        //    text-transform: uppercase;
        //}

        a:hover,
        a:focus {
            color: $color-second;
        }
    }

    svg {
        width: 32px;
        height: 32px;

        path {
            fill: $color-main;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {
    .breadcrumb {
        li {
            padding: 7.5px 10px;
        }
    }
}
