//======================================================================================================
// Actions pages
//======================================================================================================
@keyframes show-hide {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.actions-post {
    position: relative;
    display: flex;
    justify-content: center;
    column-gap: 20px;
    margin: 20px 0 50px;

    &::after {
        content: '';
        width: 100%;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-image: url(/wp-content/themes/saint-paul-trois-chateaux/assets/src/images/sprite-images/Motifs/motif-bleu.png);
        background-repeat: repeat;
    }

    li {
        position: relative;
        z-index: 2;

        a, button, div {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 0;
            padding: 17px 30px;
            background-color: $color-white;
            border: 1px solid $color-light;
            font-family: $font-family--heading;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            color: $color-main;
            transition: $duration;

            svg {
                width: 26px;
                height: 26px;
                margin-right: 14px;
                fill: $color-main;
                transition: $duration;
            }

            &:hover {
                color: $color-white;
                background-color:$color-main;

                svg {
                    fill: $color-white;
                }
            }
        }

    }

    .share-list {
        display: none;
    }

    &.shared-on {
        .action-share {
            opacity: 0;
        }

        .share-list {
            display: flex;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            li {
                margin-right: 10px;

                &:first-child {
                    a, button, div {
                        background-color: $color-bg--neutral;
                        transition: background-color ease-in-out $duration;

                        &:hover, 
                        &:focus {
                            background-color: $color-main;
                            transition: background-color ease-in-out $duration;
                        }
                    }
                }
            }

            a, button {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                width: 40px;
                height: 40px;
                padding: 0;
                background-color: $color-white;
                border: 2px solid $color-main;
                opacity: 1;
                transition: $duration;

                &:hover, 
                &:focus {
                    background-color: $color-main;

                    svg {
                        fill: $color-white;
                    }

                }

                svg {
                    margin: 0;
                    transform: scale(1.3);
                }

                &.close {
                    border-color: $color-light;

                    svg {
                        transform: scale(1.5);
                        // stroke: $color-main;
                        fill: $color-main;
                    }

                    &:hover, &:focus {
                        border-color: $color-main;

                        svg {
                            fill: $color-white;
                            // stroke: $color-white;
                        }
                    }
                }

                #post_url {
                    position: absolute;
                    opacity: 0;
                }
            }

            .copy-confirm {
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 35px;
                left: 50%;
                z-index: 2;
                width: 105px;
                height: 30px;
                opacity: 0;
                background: $color-second;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: none;
                color: $color-white;
                transform: translate(-50%, 0);

                &.show-hide {
                    animation : show-hide 2s;
                }
            }

        }
    }

}

section[data-cpt=maps] .actions-post {
    margin-top: 0;
}

//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .actions-post {
        &.shared-on {
            .share-list {
                display: flex;
                position: absolute;
                top: -30px;
                left: -123px;
            }

            .action-share {
                opacity: 1;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .actions-post li a,
    .actions-post li button,
    .actions-post li div {
        width: 50px;
        height: 50px;
        justify-content: center;
        padding: 0;

        span {
            display: none;
        }

        svg {
            margin: 0;
        }
    }

    .actions-post.shared-on .share-list {
        left: -200px;
    }
}
