.footer {
    position: relative;
    background-color: $color-main;
    padding: 80px 0 70px;

    &::after {
        content: '';
        width: 100%;
        height: 20px;
        position: absolute;
        left: 0;
        bottom: 30px;
        background-image: url(/wp-content/themes/saint-paul-trois-chateaux/assets/src/images/sprite-images/Motifs/motif-bleu.png);
        background-repeat: repeat;
    }

    &__top {
        width: 100%;
        display: flex;
        align-items: flex-start;
        column-gap: 90px;
    }

    &-links {
        display: flex;
        flex-direction: column;
        row-gap: 31px;

        &__logo {
            width: 280px;
            height: 80px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__contact {
            width: 280px;
            height: 50px;
            transition: $duration;

            svg {
                fill: $color-second !important;
                transition: $duration;
            }

            &:hover,
            &:focus {
                color: $color-white !important;

                svg {
                    fill: $color-white !important;
                }
            }
        }
    }

    &__mairie-horaires {
        display: flex;
        align-items: flex-start;
        column-gap: 80px;
        margin-top: 11px;
    }

    &-mairie {
        width: 350px;

        &__title {
            color: $color-white;
            margin: 0;
        }

        &__infos {
            line-height: 1.3em;
            color: $color-white;
            margin-top: 8px;
            transition: $duration;
        }

        &__tel {
            display: flex;
            align-items: center;
            column-gap: 5px;
            margin-top: 12px;
            transition: $duration;

            svg {
                fill: $color-white;
                transition: $duration;
            }

            &:hover,
            &:focus {
                color: $color-main--dark;

                svg {
                    fill: $color-main--dark;
                }
            }
        }
    }

    &-horaires {
        width: 350px;

        &__title {
            color: $color-white;
            margin: 0;
        }

        &__text {
            line-height: 1.35em;
            color: $color-white;
            margin-top: 9px;
        }
    }

    &__bottom {
        margin-top: 80px;
    }

    &-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;

        &__link {
            a {
                position: relative;
                display: inline-block;
                font-weight: $font-weight-bold;
                color: $color-white;
                opacity: .8;
                transition: $duration;

                &::before {
                    content: "";
                    height: 2px;
                    position: absolute;
                    left: 51%;
                    right: 51%;
                    bottom: -4px;
                    background: $color-white;
                    transition: $duration;
                }

                &:hover,
                &:focus {
                    opacity: 1;
                    
                    &::before {
                        left: 0;
                        right: 0;  
                    }
                }
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {
        padding-top: 80px;
        column-gap: 80px;

        &-links {
            align-items: center;
            row-gap: 11px;
    
            &__logo {
                width: 210px;
                height: 60px;
            }
    
            &__contact {
                width: 220px;
            }
        }
    
        &__mairie-horaires {
            column-gap: 30px;
            margin-top: 6px;
        }
    
        &-mairie { 
            width: 320px;

            &__title {
                font-size: $font-size--text-medium;
            }

            &__infos {
                margin-top: 11px;
            }
    
            &__tel {
                margin-top: 10px;
            }
        }
    
        &-horaires { 
            width: 290px; 

            &__title {
                font-size: $font-size--text-medium;
            }  

            &__text {
                line-height: 1.3em;
                margin-top: 10px;
            }
        }
    
        &__bottom {
            margin-top: 35px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        padding: 61px 0 74px;

        &__top {
            flex-wrap: wrap;
            row-gap: 38px;
        }
    
        &__mairie-horaires {
            width: 100%;
            justify-content: space-between;
            column-gap: 10px;
            margin-top: 0;
        }
    
        &-mairie { 
            width: 300px;

            &__infos {
                margin-top: 8px;
            }
        }
    
        &__bottom {
            margin-top: 50px;
        }

        &-menu {
            flex-wrap: wrap;
            justify-content: flex-start;
            row-gap: 10px;
            column-gap: 50px;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        padding: 61px 0 70px;

        &__top {
            flex-direction: column;
            align-items: center;
        }
    
        &__mairie-horaires {
            flex-direction: column;
            align-items: center;
            row-gap: 40px;
        }
    
        &-mairie { 
            &__title,
            &__infos {
                text-align: center;
            }

            &__tel {
                justify-content: center;
            }
        }

        &-horaires {
            &__title,
            &__text {
                text-align: center;
            }

            &__text {
                font-size: $font-size--text-small;
                line-height: 1.4em;
            }
        }

        &-menu {
            max-width: 300px;
            justify-content: center;
            row-gap: 20px;
        }
    }
}