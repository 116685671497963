//======================================================================================================
// Général
//======================================================================================================
.home {    
    .ui-datepicker-calendar {
        .ui-state-default {
            pointer-events: none;
            cursor: pointer;
        }
    }

    .ui-datepicker td {
        cursor: pointer;
    }

    .ui-datepicker.ui-widget-content table tbody td {
        &:hover,
        &:focus {
            a {
                background: $color-main !important;
                color: $color-white !important;
            }
        }
    }
}



.section {
    &-title {
        display: flex;
        justify-content: center;

        h2 {
            position: relative;
            display: inline-block;
            font-size: $font-size--1;
            font-weight: $font-weight-semibold;
            color: $color-text;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.05em;
            padding: 0;
            margin: 0;
            border: none;

            &::before,
            &::after {
                content: "";
                width: 28px;
                height: 18px;
                position: absolute;
            }

            &::before {
                top: -10px;
                left: -16px;
                border-top: 11px solid #8B8EC7;
                border-left: 12px solid #8B8EC7;
            }

            &::after {
                bottom: -10px;
                right: -16px;
                border-bottom: 11px solid #8B8EC7;
                border-right: 12px solid #8B8EC7;
            }
        }

        &--second {
            h2 {
                &::before {
                    border-top: 11px solid #B7A68F;
                    border-left: 12px solid #B7A68F;
                }

                &::after {
                    border-bottom: 11px solid #B7A68F;
                    border-right: 12px solid #B7A68F;
                }
            }
        }
    }
}


.btn,
button.btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    font-family: $font-family--heading;
    font-size: $font-size--text-small;
    font-weight: $font-weight-bold;
    color: $color-white;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    background-color: $color-main;
    border: 2px solid $color-main;
    box-sizing: border-box;
    z-index: 1;
    transition: $duration;

    svg {
        fill: $color-white;
        transition: $duration;
    }

    &:hover,
    &:focus {
        color: $color-main;
        background-color: transparent;

        svg {
            fill: $color-main;
        }
    }

    &--second {
        background-color: $color-second;
        border-color: $color-second;

        &:hover,
        &:focus {
            color: $color-second;

            svg {
                fill: $color-second;
            }
        }
    }

    &--main-dark {
        color: $color-text;
        background-color: $color-main--dark;
        border-color: $color-main--dark;

        &:hover,
        &:focus {
            color: $color-white;
            background-color: $color-main;
            border-color: $color-main;

            svg {
                fill: $color-white;
            }
        }
    }

    &--white {
        color: $color-text;
        background-color: $color-white;
        border-color: $color-white;
    }
}



//======================================================================================================
// Socials
//======================================================================================================
.socials {
    position: absolute;
    top: 200px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 10px;
    z-index: 2;

    &__item {
        width: 250px;
        height: 50px;
        display: flex;
        align-items: center;
        column-gap: 15px;
        background-color: $color-main;
        padding: 0 10px;
        margin-right: -200px;
        transition: $duration;

        &:hover {
            margin-right: 0;
        }
    }

    &__text {
        font-weight: $font-weight-medium;
        color: $color-white;
        transition: $duration;
    }
}



//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    margin-top: 80px;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation, 
        &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }

    .slideshow {
        width: 100%;
        height: calc(615px + 50px);

        img, video {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            object-position: top;
        }

        &__container {
            width: 100%;
            height: 100%;
            display: inherit;
            position: relative;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
                z-index: 1;
            }
        }

        &__content {
            max-width: 470px;
            position: absolute;
            left: 30px;
            bottom: 70px;
            z-index: 2;
            transition: $duration;

            &::before {
                content: '';
                width: 0;
                height: 12px;
                position: absolute;
                top: 9px;
                left: -60px;
                background-color: $color-white;
                transition: $duration;
            }

            &:hover,
            &:focus {
                left: 80px;

                &::before {
                    width: 40px;
                }
            }
        }

        &__title {
            width: 100%;
            font-family: $font-family--heading;
            font-size: 30px;
            line-height: 1.2em;
            font-weight: $font-weight-bold;
            color: $color-white;
            text-shadow: $text-shadow;
        }

        &--video {
            img {
                display: none; 
            }
        }

        // Pagination (bullet)
        &__pagination {
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;
            position: absolute;
            right: 15px;
            bottom: 70px;
            z-index: 2;
    
            .swiper-pagination-bullet {
                width: 15px;
                height: 15px;
                transition: .25s;
                background-color: $color-white;
                border-radius: 0;
                opacity: 1;
    
                &:hover {
                    background-color: $color-main;
                }
    
                &-active {
                    width: 60px;
                    background-color: $color-main;
                }
            }
        }
    }

    .search--home {
        width: 630px;
        position: absolute;
        top: 280px;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
        z-index: 2;
    
    
        .search {
            &-container {
                height: 60px;
                position: relative;
                display: flex;
    
                &::before {
                    content: "";
                    width: 28px;
                    height: 18px;
                    position: absolute;
                    top: -5px;
                    left: -4px;
                    border-top: 11px solid $color-main;
                    border-left: 12px solid $color-main;
                }
    
                input {
                    flex: 1;
                    height: 100%;
                    font-family: $font-family;
                    color: $color-text;
                    background-color: $color-white--rgba;
                    padding: 0 22px;
                    border: none;
                    border-radius: 0;
                    transition: $duration;
    
                    &::placeholder {
                        color: $color-text;
                    }

                    &:focus {
                        background-color: $color-white;
                    }
                }
            }
    
            &-svg {
                width: 55px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-main;
                transition: $duration;
    
                svg {
                    width: 30px;
                    height: 30px;
                }

                &:hover,
                &:focus {
                    background-color: $color-second;
                }
            }
        }

        .searchResult {
            background: $color-white !important;
            border-top-color: $color-main;

            li {
                background-color: transparent !important;
            }
        }
    }
}



//======================================================================================================
// Quick Access
//======================================================================================================
.qa {
    position: relative;

    &::before,
    &::after {
        content: '';
        width: 100%;
        height: 20px;
        position: absolute;
        left: 0;
        background-image: url(/wp-content/themes/saint-paul-trois-chateaux/assets/src/images/sprite-images/Motifs/motif-bleu.png);
        background-repeat: repeat;
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 23px;
    }

    &__container {
        position: relative;
        display: flex;
        justify-content: space-between;
        z-index: 1;
    }

    &-item {
        width: 120px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin-top: 62px;

        &__text {
            font-family: $font-family--heading;
            font-size: $font-size--text-small;
            line-height: 1.2em;
            font-weight: $font-weight-bold;
            color: $color-text;
            text-transform: uppercase;
            text-align: center;
        }

        &--portail {
            width: 270px;
            height: 310px;
            position: relative;
            background-color: $color-white;
            border: 20px solid $color-light;
            padding-top: 38px;
            margin-top: -20px;
            box-shadow: $shadow;

            &::before,
            &::after {
                content: '';
                width: 30px;
                height: 22px;
                position: absolute;
                transition: $duration;
            }

            &::before {
                top: -1px;
                left: -1px;
                border-top: 2px solid $color-main;
                border-left: 2px solid $color-main;
            }

            &::after {
                right: -1px;
                bottom: -1px;
                border-right: 2px solid $color-main;
                border-bottom: 2px solid $color-main;
            }

            .qa-item__icon {
                width: 120px;
                height: 120px;
            }

            .qa-item__text {
                font-size: $font-size--text-medium;
                margin-bottom: 38px;
            }

            &:hover,
            &:focus {
                &::before,
                &::after {
                    width: 50px;
                    height: 42px;
                }
            }
        }

        &__icon {
            width: 85px;
            height: 85px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 5px solid $color-main;
            border-radius: 50%;
            transition: $duration;

            svg {
                width: 100%;
                height: 100%;
                fill: $color-main;
                transition: $duration;
            }
        }

        &:hover,
        &:focus {
            .qa-item__icon {
                background-color: $color-main;
                transform: translateY(-7.5px);

                svg {
                    fill: $color-white;
                }
            }
        }
    }
}



//======================================================================================================
// Actualités
//======================================================================================================
.ac {
    position: relative;
    padding-bottom: 75px;
    margin-top: 80px;

    &::after {
        content: '';
        width: 100%;
        height: 20px;
        position: absolute;
        left: 0;
        bottom: 90px;
        background-image: url(/wp-content/themes/saint-paul-trois-chateaux/assets/src/images/sprite-images/Motifs/motif-dore.png);
        background-repeat: repeat;
    }

    &__effect {
        width: 100%;
        height: 84.5%;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        background-color: $color-second--light;
        z-index: -1;

        svg {
            width: 1500px;
            height: 115px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__title {
        color: $color-dark;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-top: 49px;

        &--small {
            justify-content: flex-start;
            column-gap: 30px;
        }
    }

    &-bigitem {
        width: 570px;
        position: relative;
        display: flex;
        flex-direction: column;

        &--1 {
            animation-name: fadeInLeft;
        }

        &--2 {
            animation-name: fadeInRight;
        }

        &__img {
            width: 100%;
            height: 355px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
            }
        }

        &__infos {
            width: 480px;
            height: 250px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: $color-white;
            padding: 30px 40px 20px;
            margin: -90px auto 0;

            &::before {
                content: '';
                width: 55px;
                height: 36px;
                position: absolute;
                top: -9px;
                left: -15px;
                border-top: 22px solid $color-second;
                border-left: 24px solid $color-second;
                transition: $duration
            }

            &::after {
                content: '';
                width: 80px;
                height: 80px;
                position: absolute;
                bottom: -1px;
                right: -1px;
                border-right: 2px solid $color-second;
                border-bottom: 2px solid $color-second;
                transition: $duration
            }
        }

        &__category {
            font-size: $font-size--text-small;
            color: $color-text;
            text-transform: uppercase;
        }

        &__title {
            line-height: 1.1em;
            color: $color-second;
            margin: 4px 0 0;
            transition: $duration
        }

        &__text {
            color: $color-dark;
            margin-top: 9px;
        }

        &:hover,
        &:focus {
            .ac-bigitem {
                &__img img {
                    transform: scale(1.2);
                }

                &__infos {
                    &::before {
                        border-color: $color-main;
                    }

                    &::after {
                        width: 120px;
                        height: 120px;
                        border-color: $color-main;
                    }
                }

                &__title {
                    color: $color-main;
                }
            }
        }
    }

    &-smallitem {
        width: 270px;
        position: relative;
        display: flex;
        flex-direction: column;

        &__img {
            width: 100%;
            height: 170px;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-bg--image no-repeat center;
        }

        &__category {
            position: absolute;
            left: 0;
            bottom: 0;
            font-size: $font-size--text-small;
            color: $color-white;
            text-transform: uppercase;
            background: $color-second;
            padding: 2px 16px;
            transition: $duration;

        }

        &__infos {
            width: 100%;
            padding: 30px 0 20px;
        }

        &__title {
            font-size: $font-size--text-large;
            line-height: 1.1em;
            color: $color-dark;
            margin: 13px 0 0;
        }

        &:hover,
        &:focus {
            .ac-smallitem {
                &__img img {
                    transform: scale(1.2);
                }

                &__category {
                    background-color: $color-main;
                }
            }
        }
    }

    &__link {
        width: 370px;
        height: 50px;
        margin: 70px auto 0;
        transition: $duration;

        &:hover,
        &:focus {
            background-color: $color-second--light;

            svg {
                transform: rotate(90deg);
            }
        }
    }
}



//======================================================================================================
// Agenda
//======================================================================================================
.ag {
    position: relative;
    padding-bottom: 50px;
    margin-top: 85px;

    &::after {
        content: '';
        width: 100%;
        height: 145px;
        position: absolute;
        left: 0;
        bottom: -60px;
        background-image: url(/wp-content/themes/saint-paul-trois-chateaux/assets/src/images/sprite-images/Motifs/motif-bleu.png);
        background-repeat: repeat;
    }

    &-filters {
        display: flex;
        align-items: flex-end;
        column-gap: 30px;
        margin-top: 40px;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 1px;

                
            select,
            input {
                height: 38px;
                font-weight: 400;
                color: $color-text;
                background-color: $color-white;
                padding: 0 11px;
                border: 1px solid $color-main !important;
                border-radius: 0;
    
                &::placeholder {
                    color: $color-text;
                }
            }
    
            select {
                width: 370px;
                -webkit-appearance: none;
                -moz-appearance: none;
            }
    
            input {
                width: 225px;
            }
        }

        &__label {
            font-weight: $font-weight-bold;
            color: $color-text;
            margin: 0;
        }

        &__dates {
            display: flex;
            column-gap: 30px;
        }

        &__select,
        &__input {
            position: relative;

            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &__select svg {
            right: 13px;
        }

        &__input svg {
            right: 6px;
        }

        &__search {
            width: 250px;
            height: 38px;
            margin: 0 0 0 auto;
            border-radius: 0;
        }
    }

    &__content {
        display: flex ;
        flex-wrap: wrap;
        margin-top: 40px;
    }

    &-item {
        width: 385px;
        height: 450px;
        display: flex;
        flex-direction: column;
        border: 1px solid $color-white;

        &:nth-child(3n + 2) {
            width: 400px;
        }

        &__top {
            width: 100%;
            height: 290px;
            position: relative;
            display: flex;
            align-items: flex-end;
        }

        &__dates {
            position: relative;
            display: flex;
            align-items: center;
            background-color: $color-main;

            svg {
                width: 37px;
                height: 37px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                fill: $color-white;
            }
        }

        &__category {
            display: none;
            font-size: $font-size--text-small;
            color: $color-white;
            text-transform: uppercase;
            background: $color-main;
            padding: 5px 16px 5px 5px;
        }

        &__img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;
            z-index: -1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $duration;
            }

            &--no-image {
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) $color-bg--image no-repeat center;
            }            
        }

        &__infos {
            width: 100%;
            height: 160px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: $color-second;
            padding: 21px 25px 15px;
        }

        &__title {
            position: relative;
            line-height: 1.1em;
            color: $color-main;
            padding: 15px 0 0 16px;
            margin: 0 0 14px;

            &::before {
                content: "";
                width: 30px;
                height: 22.5px;
                position: absolute;
                top: -2px;
                left: -2px;
                border-top: 2px solid $color-main;
                border-left: 2px solid $color-main;
                transition: $duration;
            }
        }

        &__time,
        &__location {
            display: flex;
            align-items: center;
            column-gap: 2px;
            color: $color-main;
        }

        &__time {
            margin-bottom: 5px;
        }

        &:nth-child(odd) {
            .ag-item__infos {
                background-color: $color-main--dark;
            }
        }

        &:nth-child(even) {
            .ag-item__infos {
                background-color: $color-main--light;
            }
        }

        &:hover,
        &:focus {
            .ag-item {
                &__img img {
                    transform: scale(1.2);
                }

                &__title::before {
                    width: 50px;
                    height: 42.5px;
                }
            }
        }
    }

    &-date {
        width: 80px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__day {
            font-family: $font-family--heading;
            font-size: 32px;
            font-weight: $font-weight-bold;
            color: $color-white;
        }

        &__month {
            color: $color-white;
            text-transform: capitalize;
            margin-top: -4px;
        }
    }

    &__link {
        width: 370px;
        height: 50px;
        margin: 60px auto 0;

        &:hover,
        &:focus {
            background-color: $color-white;

            svg {
                transform: rotate(90deg);
            }
        }
    }
}


//======================================================================================================
// Newsletter
//======================================================================================================
.newsletter {
    height: 120px;
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 35px;
    background-color: $color-white;
    padding: 0 50px 0 20px;
    border: 20px solid $color-light;
    z-index: 1;

    &::before,
    &::after {
        content: "";
        position: absolute;
    }

    &::before {
        width: 23px;
        height: 23px;
        top: -1px;
        left: -1px;
        border-top: 2px solid $color-main;
        border-left: 2px solid $color-main;
    }

    &::after {
        width: 40px;
        height: 40px;
        bottom: -1px;
        right: -1px;
        border-bottom: 2px solid $color-main;
        border-right: 2px solid $color-main;
    }

    &__title {
        display: flex;
        align-items: center;
        column-gap: 15px;

        h2 {
            padding: 0;
            margin: 0;
            border: none;

            &::before {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &__text {
       font-size: $font-size--text-normal;
       color: $color-text; 
    }

    &__button {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin: 0 0 0 auto;

        svg {
            fill: $color-main;
            transition: $duration;
        }

        span {
            font-family: $font-family--heading;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            color: $color-text;
            text-transform: uppercase;
            letter-spacing: .05rem;
            transition: $duration;
        }

        &:hover,
        &:focus {
            svg {
                fill: $color-second;
                transform: rotate(90deg);
            }

            span {
                color: $color-second;
            }
        }
    }
}



//======================================================================================================
// Retour en images
//======================================================================================================
.retour {
    position: relative;
    padding-bottom: 80px;
    margin-top: 98px;

    &::after {
        content: '';
        width: 100%;
        height: 20px;
        position: absolute;
        left: 0;
        bottom: 96px;
        background-image: url(/wp-content/themes/saint-paul-trois-chateaux/assets/src/images/sprite-images/Motifs/motif-dore.png);
        background-repeat: repeat;
    }

    .section-title h2 br {
        display: none;
    }

    &__effect {
        width: 100%;
        position: absolute;
        top: 190px;

        svg {
            width: 2200px;
            height: 253px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            fill: #E2DBD2;
        }
    }

    &-items {
        height: 587px;
        position: relative;
        display: flex;
        margin-top: 38px;
    }

    &-item {
        height: 170px;
        position: absolute;
        display: flex;
        background: $color-bg--image;
        border: 1px solid $color-white;

        &__media {
            width: 100%;
            height: 100%;
            position: relative;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: $color-second;
                opacity: 0;
                z-index: 1;
                transition: $duration;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            svg {
                width: 100px;
                height: 100px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
        }

        &__title {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            font-family: $font-family--heading;
            font-size: $font-size--text-medium;
            font-weight: 700;
            color: $color-white;
            text-align: center;
            padding: 0 15px;
            opacity: 0;
            z-index: 2;
            transition: $duration;
        }

        &--1 {
            width: 395px;
            height: 235px;
            left: 0;
            bottom: 0;
            transition-delay: 500ms;
        }

        &--2 {
            width: 315px;
            height: 200px;
            left: 80px;
            bottom: 235px;
            transition-delay: 1250ms;
        }

        &--3 {
            width: 490px;
            height: 300px;
            top: 0;
            left: 395px;
            transition-delay: 0ms;
        }

        &--4,
        &--5 {
            width: 285px;
            height: 195px;
        }

        &--4 {
            top: 300px;
            left: 395px;
            transition-delay: 750ms;
        }

        &--5 {
            top: 105px;
            right: 0;
            transition-delay: 250ms;
        }

        &--6 {
            width: 430px;
            height: 265px;
            top: 300px;
            right: 60px;
            transition-delay: 1000ms;
        }

        &--video:hover {
            cursor: pointer;
        }
    }

    &__button {
        width: 270px;
        height: 50px;
        margin: 29px 60px 0 auto;

        &:hover,
        &:focus {
            background-color: $color-white;

            svg {
                transform: rotate(90deg);
            }
        }
    }
}

a.retour-item--image {
    &:hover,
    &:focus {
        cursor: pointer;

        .retour-item__media::after {
            opacity: .8;
        }

        .retour-item__title {
            opacity: 1;
        }
    }
}


.popup-area {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background: $color-black--rgba;

    &.show-popup {
        visibility: visible;
        opacity: 1;
    }

    video,
    iframe {
        max-width: 100%;
        max-height: 100%;
    }

    .accordion {
        height: 0;
        color: $color-white;

        svg {
            fill: $color-white;
        }
    }

    .close-area-popup {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 60px;
        right: calc((100% - 1170px)/2);
        background: $color-light;
        border-radius: 50%;
        transition: $duration;

        &::before {
            content: '\f335';
            font: normal 30px/1 'dashicons';
            color: $color-main;
            transition: $duration;
        }

        &:hover {
            cursor: pointer;
            background-color: $color-main;

            &::before {
                color: $color-white;
            }
        }
    }
}



//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {
    position: relative;
    background-color: $color-light;
    padding: 80px 0 75px;

    &::after {
        content: '';
        width: 100%;
        height: 20px;
        position: absolute;
        left: 0;
        bottom: 90px;
        background-image: url(/wp-content/themes/saint-paul-trois-chateaux/assets/src/images/sprite-images/Motifs/motif-bleu.png);
        background-repeat: repeat;
    }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .section-title {
        position: relative;
        margin: 7px 0 0 43px;
        z-index: 2;
    }

    .swiper {
        &-container {
            width: calc(100% - 50px);
            position: relative;
            padding-left: 13px;
            margin: -61px 0 0 0;
        }

        &-slide {
            display: flex;

            &-active {
                width: 390px !important;
            }
        }
    }

    &-content {
        width: 383px;
        height: 500px;
        position: absolute;
        top: 0;
        left: -382px;
        padding-top: 110px;
        background-color: $color-light;

        &__container {
            width: 295px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__title {
            font-size: 1.75rem;
            line-height: 1.1em;
            color: $color-text;
            text-align: center;
            margin: 0;
        }

        &__infos {
            margin-top: 20px;

            p {
                color: $color-text;
                text-align: center;
            }

            span {
                text-transform: uppercase;
            }
        }

        &__buttons {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            margin-top: 42px;

            a {
                width: 170px;
                height: 46px;
            }

            svg {
                fill: $color-main;
            }
        }
    }

    &-image {
        width: 100%;
        height: 510px;
        display: flex;
        background-color: $color-bg--image;
        box-shadow: $shadow;

        &--no-image {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-bg--image;
        
            svg {
              width: 40%;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__prev,
    &__next {
        position: absolute;
        top: 47.5%;
        transform: translateY(-50%);
        z-index: 2;

        svg {
            fill: $color-main;
            transition: $duration;
        }

        &:hover,
        &:focus {
            cursor: pointer;

            svg {
                fill: $color-second;
            }
        }
    }

    &__prev {
        left: 28.5%;
    }

    &__next {
        right: 0;
    }

    &__link {
        width: 295px;
        height: 50px;
        margin-top: 15px;

        &:hover,
        &:focus {
            background-color: $color-light;

            svg {
                transform: rotate(90deg);
            }
        }
    }
}



//======================================================================================================
// Social wall
//======================================================================================================
.social-wall {
    position: relative;
    padding: 82px 0 60px;

    &::after {
        content: '';
        width: 100%;
        height: 20px;
        position: absolute;
        left: 0;
        bottom: 75px;
        background-image: url(/wp-content/themes/saint-paul-trois-chateaux/assets/src/images/sprite-images/Motifs/motif-dore.png);
        background-repeat: repeat;
        z-index: -1;
    }

    &__effect {
        width: 100%;
        height: 64.5%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        background-color: $color-second--light;
        z-index: -1;

        svg {
            width: 1500px;
            height: 115px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    #ff-stream-1 {
        background-color: transparent;
    }

    .ff-stream {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 45px;
        background-color: transparent;
        padding: 0;
        margin-top: 20px;
    }

    .ff-header {
        order: 2;

        .ff-filter-holder {
            display: flex;
            column-gap: 20px;
            margin: 0;

            &:first-child {
                margin-top: 0;
            }

            .ff-filter {
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $color-second !important;
                margin: 0;
                border-radius: 0;

                &.ff-type-all {
                    font-family: $font-family--heading;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight-bold;
                }
    
                i::before {
                    color: $color-white;
                }

                &--active {
                    color: $color-second;
                    background-color: $color-white !important;

                    i::before {
                        color: $color-second;
                    }
                }
            }
        }

        .ff-search {
            display: none;
        }
    }

    .ff-stream-wrapper {
        order: 1;
        width: 100%;
        padding: 0;
        margin: 30px 0 0;
    }

    .ff-item {
        .ff-img-holder {
            height: 180px !important;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        h4,
        .ff-content {
            margin: 7% 7% 2%;
        }

        .ff-userpic {
            border-radius: $border-radius--round;
        }

        &-meta {
            margin: auto 7% 7%;
        }

        &-cont {
            height: 400px !important;
            display: flex;
            flex-direction: column;
        }

        .ff-item-bar {
            border-top-color: $color-second !important;
        }
    }

    .ff-loadmore-wrapper {
        display: none;
    }

    .picture-item__inner {
        box-shadow: none;
    }

    .ff-instagram .ff-icon-inner {
        background-color: #BF32A0 !important;
    }

    .ff-youtube a,
    .ff-facebook a,
    .ff-instagram a {
        color: $color-text !important;
    }

    .ff-youtube .ff-item-bar a,
    .ff-facebook .ff-item-bar a,
    .ff-instagram .ff-item-bar a,
    .ff-icon-share:before {
        color: $color-second !important;
    }

    #ff-stream-1 .ff-nickname,
    #ff-stream-1 .ff-nickname::after,
    #ff-stream-1 .ff-timestamp {
        color: $color-text;
    }
}

//======================================================================================================
// Usefull links
//======================================================================================================
.usefull {
    position: relative;
    padding: 87px 0 80px;

    &__content {
        position: relative;
        margin-top: 50px;

        &::after {
            content: '';
            width: 100%;
            height: 20px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-image: url(/wp-content/themes/saint-paul-trois-chateaux/assets/src/images/sprite-images/Motifs/motif-bleu.png);
            background-repeat: repeat;
            z-index: -1;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        column-gap: 50px;
    }

    .swiper-container {
        width: 970px;
    }

    &__item {
        height: 170px;
        display: flex;
        background-color: $color-bg--image;
        border: 1px solid $color-light;
        box-sizing: border-box;
        overflow: hidden;
        transition: $duration;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: $duration;
        }

        &--1 {
            transition-delay: 750ms;
        }

        &--2 {
            transition-delay: 250ms;
        }

        &--3 {
            transition-delay: 1000ms;
        }

        &--4 {
            transition-delay: 0ms;
        }

        &--5 {
            transition-delay: 500ms;
        }
    }

    &__navigation {
        width: 50px;
        height: 50px;
        background-color: $color-main;
        border: 2px solid $color-main;
        transition: $duration;

        svg {
            width: 100%;
            height: 100%;
            fill: $color-white;
            transition: $duration;
        }

        &:hover,
        &:focus {
            background-color: $color-white;
            cursor: pointer;

            svg {
                fill: $color-main;
            }
        }
    }
}

a.usefull__item:hover,
a.usefull__item:focus {
    border-color: $color-main;

    img {
        transform: scale(1.1);
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        .search--home {
            top: 230px;
        }

        .slideshow {
            height: calc(490px + 50px);

            &__content {
                left: 20px;
                bottom: 40px;
            }

            &__title {
                font-size: 26px;
            }

            &__pagination {
                right: 10px;
                bottom: 40px;
            }
        }
    }



    //======================================================================================================
    // Quick Access
    //======================================================================================================
    .qa {
        &::after {
            bottom: 20px;

        }
        &-item {
            width: 115px;
            height: 140px;
            margin-top: 45px;

            &--portail {
                width: 210px;
                height: 270px;
                padding-top: 19px;
                margin-top: -20px;

                .qa-item__text {
                    margin-bottom: 18px;
                }
            }
        }
    }



    //======================================================================================================
    // Actualités
    //======================================================================================================
    .ac {
        padding-bottom: 70px;

        &::after {
            bottom: 85px;
        }

        &__effect {
            height: 77.5%;

            svg {
                width: 1200px;
                height: 92px;
            }
        }

        &__content {
            margin-top: 59px;

            &--small {
                column-gap: 20px;
                margin-top: 50px;
            }
        }

        &-bigitem {
            width: 460px;

            &__img {
                height: 285px;
            }

            &__infos {
                width: 410px;
                height: 260px;
                padding: 31px 30px 20px;
            }

            &__text {
                margin-top: 4px;
            }
        }

        &-smallitem {
            width: 220px;

            &__img {
                height: 140px;
            }

            &__title {
                font-size: $font-size--text-medium;
                margin: 10px 0 0;
            }
        }
    }



    //======================================================================================================
    // Agenda
    //======================================================================================================
    .ag {
        margin-top: 80px;

        &-filters {
            column-gap: 20px;
            margin-top: 50px;

            &__item {                
                select {
                    width: 300px;
                }
    
                input {
                    width: 185px;
                }
            }

            &__dates {
                column-gap: 20px;
            }

            &__search {
                width: 210px;
            }
        }

        &-item {
            width: 310px;
            height: 395px;

            &:nth-child(3n + 2) {
                width: 320px;
            }

            &__top {
                height: 235px;
            }

            &__infos {
                padding: 21px 20px 15px;
            }

            &__title {
                font-size: $font-size--text-medium;
                line-height: 1.1em;
                padding: 15px 0 0 15px;
                margin: 0 0 19px;
            }
        }
    }


    //======================================================================================================
    // Newsletter
    //======================================================================================================
    .newsletter {
        column-gap: 20px;
        padding: 0 20px;

        &__title {
            column-gap: 13px;

            h2 {
                font-size: 30px;
            }
        }

        &__text {
            font-size: $font-size--text;
        }
    }



    //======================================================================================================
    // Retour en images
    //======================================================================================================
    .retour {
        &__effect {
            top: 165px;

            svg {
                width: 1800px;
                height: 210px;
            }
        }

        &-items {
            height: 471px;
        }

        &-item {
            &__media {
                svg {
                    width: 80px;
                    height: 80px;
                }
            }

            &--1 {
                width: 315px;
                height: 190px;
            }

            &--2 {
                width: 250px;
                height: 160px;
                left: 65px;
                bottom: 190px;
            }

            &--3 {
                width: 395px;
                height: 240px;
                left: 315px;
            }

            &--4,
            &--5 {
                width: 230px;
                height: 155px;
            }

            &--4 {
                top: 240px;
                left: 315px;
            }

            &--5 {
                top: 85px;
            }

            &--6 {
                width: 345px;
                height: 210px;
                top: 240px;
                right: 50px;
            }
        }

        &__button {
            margin: 30px 50px 0 auto;
        }
    }


    .popup-area {
        .close-area-popup {
            right: calc((100% - 960px)/2);
        }
    }



    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .kiosque {
        padding: 73px 0 70px;

        &::after {
            bottom: 85px;  
        }

        .section-title {
            margin: 7px 0 0 32px;
        }

        .swiper {
            &-container {
                width: calc(100% - 57px);
                padding-left: 90px;
                margin: -45px 0 0 0;
            }

            &-slide {
                &-active {
                    width: 305px !important;
                }
            }
        }

        &-content {
            width: 357px;
            height: 400px;
            left: -356px;
            padding-top: 83px;

            &__container {
                width: 270px;
            }

            &__infos {
                margin-top: 18px;
            }

            &__buttons {
                row-gap: 10px;
                margin-top: 20px;
            }
        }

        &-image {
            height: 400px;
        }

        &__next,
        &__prev {
            top: 48%;
        }

        &__prev {
            left: 32%;
        }

        &__link {
            width: 270px;
            margin-top: 25px;
        }
    }


    //======================================================================================================
    // Social Wall
    //======================================================================================================
    .social-wall {
        &__effect svg {
            width: 1200px;
            height: 92px;
        }
    }



    //======================================================================================================
    // Usefull links
    //======================================================================================================
    .usefull {
        padding: 75px 0 83px;

        &__content {
            margin-top: 48px;
        }

        &__container {
            column-gap: 30px;
        }

        .swiper-container {
            width: 780px;
        }

        &__item {
            height: 140px;            
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    [data-aos] {
        visibility: visible;
        transform: none;
    }

    .socials {
        position: fixed;
        top: 160px;
    }

    body.admin-bar .socials {
        top: calc(160px + 32px);
    }


    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        position: relative;
        margin-top: 0;

        .search--home {
            display: none;
        }

        .slideshow {
            height: 390px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__content,
            &__pagination {
                bottom: 50px;
            }

            &__content {
                max-width: 370px;
            }
        }
    }



    //======================================================================================================
    // Quick Access
    //======================================================================================================
    .qa {
        &::after {
            top: 105px;
            bottom: unset;
        }

        &__container {
            max-width: 445px;
            position: relative;
            flex-wrap: wrap;
            row-gap: 30px;
            padding: 175px 0 0;
        }

        &-item {
            margin-top: 0;

            &--portail {
                width: 500px;
                height: 165px;
                position: absolute;
                top: -20px;
                left: 50%;
                transform: translateX(-50%);
                flex-direction: row;
                justify-content: center;
                column-gap: 25px;
                padding-top: 0;
                margin-top: 0;

                .qa-item__icon {
                    width: 85px;
                    height: 85px;
                }

                .qa-item__text {
                    margin-bottom: 0;

                    br {
                        display: none;
                    }
                }
            }
        }
    }



    //======================================================================================================
    // Actualités
    //======================================================================================================
    .ac {
        &__effect {
            height: 88%;

            svg {
                width: 960px;
                height: 74px;
            }
        }
        
        &__content {
            margin-top: 40px;

            &--small {
                justify-content: space-between;
                flex-wrap: wrap;
                row-gap: 29px;
                column-gap: 0;
                margin-top: 44px;
            }
        }

        &-bigitem {
            width: 300px;

            &__img {
                height: 185px;
            }

            &__infos {
                width: 270px;
                height: 290px;
                padding: 21px 19px 20px;
                margin-top: -16px;

                &::before {
                    width: 37px;
                    height: 24px;
                    top: -6px;
                    left: -11px;
                    border-top: 14px solid $color-second;
                    border-left: 16px solid $color-second;
                }
            }

            &__title {
                font-size: 24px;
            }
        }

        &-smallitem {
            width: 300px;

            &__img {
                height: 190px;
            }
        }

        &__link {
            margin: 65px auto 0;
        }
    }



    //======================================================================================================
    // Agenda
    //======================================================================================================
    .ag {
        margin-top: 69px;

        &::after {
            height: 175px;
            bottom: -90px;
        }

        &-filters {
            flex-wrap: wrap;
            row-gap: 10px;
            margin-top: 30px;
        }

        &__content {
            margin-top: 29px;
        }

        &-item {
            &:nth-child(3n + 2) {
                width: 310px;
            }
        }

        &__link {
            margin-top: 51px;
        }
    }


    //======================================================================================================
    // Newsletter
    //======================================================================================================
    .newsletter {
        height: 174px;
        flex-wrap: wrap;
        column-gap: 20px;
        padding: 10px 30px;

        &__title {
            order: 1;
            column-gap: 13px;

            h2 {
                font-size: 30px;
            }
        }

        &__text {
            order: 3;
            width: 100%;
            font-size: $font-size--text;
        }

        &__button {
            order: 2;
        }
    }



    //======================================================================================================
    // Retour en images
    //======================================================================================================
    .retour {
        padding-bottom: 60px;
        margin-top: 88px;

        &::after {
            bottom: 75px;
        }

        &__effect {
            display: none;
        }

        &-items {
            height: auto;
            flex-wrap: wrap;
        }

        &-item {
            width: 310px;
            height: 195px;
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            bottom: unset;
        }

        &__button {
            margin: 50px auto 0;
        }
    }


    .popup-area {
        .close-area-popup {
            right: calc((100% - 640px)/2);
        }
    }



    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .kiosque {
        padding: 79px 0 60px;

        &::after {
            bottom: 75px;  
        }

        .section-title {
            margin: 0;
        }

        &__container {
            align-items: center;
        }

        .swiper {
            &-container {
                width: 305px;
                padding: 0;
                margin: 50px auto 0
            }

            &-slide {
                flex-direction: column-reverse;
                align-items: center;
                
                &-active {
                    width: auto !important;
                }
            }
        }

        &-content {
            width: 270px;
            height: auto;
            position: relative;
            left: unset;
            padding-top: 0;
            margin-top: 29px;

            &__container {
                width: 100%;
            }

            &__buttons {
                flex-direction: row;
                column-gap: 10px;
                margin-top: 22px;

                a {
                    width: 46px;
                    height: 46px;

                    .btn__text {
                        display: none;
                    }
                }
            }
        }

        &-image {
            width: 305px;
            height: 400px;
        }

        &__prev,
        &__next {
            top: 323px;
        }

        &__prev {
            left: 76px;
        }

        &__next {
            right: 76px;
        }

        &__link {
            margin-top: 40px;
        }
    }


    //======================================================================================================
    // Social Wall
    //======================================================================================================
    .social-wall {
        &__effect svg {
            width: 960px;
            height: 74px;
        }
    }
    


    //======================================================================================================
    // Usefull links
    //======================================================================================================
    .usefull {
        .swiper-container {
            width: 460px;
        } 
    }
}


// 640
@media screen and (max-width: $small) {
    .section-title h2 {
        font-size: 40px;
    }

    .socials {
        display: none;
    }

    body.admin-bar .socials {
        top: unset;
    }

    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .home-slideshow {
        .slideshow {
            height: 260px;

            &__content {
                width: 300px;
                bottom: 75px;

                &:hover,
                &:focus {
                    left: 20px;

                    &::before {
                        width: 0;
                    }
                }
            }

            &__title {
                font-size: $font-size--text-large;
            }

            &__pagination {
                width: 100%;
                bottom: 40px;
            }
        }
    }



    //======================================================================================================
    // Quick Access
    //======================================================================================================
    .qa {
        &::after {
            top: 105px;
            bottom: unset;
        }

        &__container {
            max-width: 280px;
            row-gap: 30px;
        }

        &-item {
            margin-top: 0;

            &--portail {
                width: 300px;
                column-gap: 30px;
            }

            .qa-item__text br {
                display: block;
            }
        }
    }



    //======================================================================================================
    // Actualités
    //======================================================================================================
    .ac {
        margin-top: 73px;

        &__effect {
            height: 90.5%;
            
            svg {
                width: 640px;
                height: 49px;
            }
        }

        .section-title h2 {    
            &::before {
                top: -13px;
                left: 13px;
            }
    
            &::after {
                right: 13px;
                bottom: -13px;
            }
        }

        &__content {
            flex-direction: column;
            row-gap: 20px;

            &--small {
                row-gap: 20px;
                margin-top: 19px;
            }
        }

        &-bigitem {
            &__infos {
                margin-top: -14px;
            }
        }

        &__link {
            width: 100%;
            margin: 45px auto 0;
        }
    }



    //======================================================================================================
    // Agenda
    //======================================================================================================
    .ag {
        margin-top: 60px;

        &::after {
            height: 225px;
            bottom: -140px;
        }

        &-filters {
            flex-wrap: wrap;
            row-gap: 10px;
            margin-top: 47px;

            &__item input {
                width: 140px;
            }

            &__search {
                width: 300px;
                margin-top: 10px;
            }
        }

        &-item {
            border: 0;

            &:nth-child(3n + 2) {
                width: 300px;
            }
        }

        &__link {
            width: 100%;
            margin-top: 40px;
        }
    }


    //======================================================================================================
    // Newsletter
    //======================================================================================================
    .newsletter {
        height: 258px;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 22px;
        padding: 18px 25px;

        &__text {
            order: 2;
        }

        &__button {
            order: 3;
            margin: 0;
        }
    }



    //======================================================================================================
    // Retour en images
    //======================================================================================================
    .retour {
        margin-top: 64px;

        &::after {
            bottom: 75px;
        }

        .section-title h2 br {
            display: block;
        }

        &-items {
            margin-top: 40px;
        }

        &-item {
            width: 100%;
        }

        &__button {
            width: 100%;
            margin-top: 40px;
        }
    }

    .popup-area {
        .close-area-popup {
            right: calc((100% - 320px)/2);
        }
    }



    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .kiosque {
        padding-top: 75px;

        .swiper {
            &-container {
                width: 270px;
                margin: 42px auto 0
            }
        }

        &-content {
            margin-top: 29px;

            &__infos {
                margin-top: 21px;
            }

            &__buttons {
                margin-top: 20px;
            }
        }

        &-image {
            width: 225px;
            height: 295px;
        }

        &__prev,
        &__next {
            top: 240px;
        }

        &__prev {
            left: -11px;
        }

        &__next {
            right: -11px;
        }

        &__link {
            width: 100%;
            margin-top: 40px;
        }
    }


    //======================================================================================================
    // Social Wall
    //======================================================================================================
    .social-wall {
        .section-title--second h2:before {
            left: 40px;
        }

        .section-title--second h2:after {
            right: 40px;
        }
        
        &__effect {
            height: 84.5%;
            
            svg {
                width: 640px;
                height: 49px;
            }
        }
    }



    //======================================================================================================
    // Usefull links
    //======================================================================================================
    .usefull {
        padding: 75px 0 60px;

        &__content {
            margin-top: 46px;

            &::after {
                top: unset;
                transform: unset;
                bottom: 15px;
            }
        }

        &__container {
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 17px;
            column-gap: 20px;
        }

        .swiper-container {
            order: 1;
            width: 300px;
        } 

        &__navigation {
            order: 2;
        }
    }
}