.cover {
    position: relative;
    margin-top: 80px;

    &__image-wrapper {
        width: 100%;
        height: 550px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        position: relative;
        background: $color-light;
        padding: 0 0 30px;
    }

    &__title {
        font-weight: $font-weight-semibold;
        color: $color-main;
        margin: 0 0 10px;
    }
      
    
    &__intro {
        font-size: $font-size--text-normal;
        color: $color-text;
        margin: 20px 0 0;
    }

    &--no-breadcrumb {
        .cover__infos {
            padding: 30px 0;
        }
    }
}

.search .cover__informations {
    padding-top: 30px;
}




//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__image-wrapper {
            height: 500px;
        }
    }
}



// 960
@media screen and (max-width: $medium) {
    .cover {
        margin-top: 0;
        
        &__image-wrapper {
            height: 380px;
        }
    }
}



// 640
@media screen and (max-width: $small) {
    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 255px;
        }
    }
}